import { graphql } from "gatsby";
import React from "react";
import IndexPage from "../../components/pages";

const Index = (props) => <IndexPage {...props} />;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
    }
    prodReviews: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "review" }
        tag_list: { in: "p-home-prodotto" }
      }
    ) {
      nodes {
        slug
        content
      }
    }
    faqs: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "faq" }
        tag_list: { in: "p-home" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
    reviews: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "review" }
        tag_list: { in: "p-home" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
    blog: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "blog" }
        tag_list: { in: "p-home" }
      }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
    imgFeat: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "image-feat" }
        tag_list: { in: "p-home" }
      }
    ) {
      nodes {
        content
      }
    }
    imgFeatTab: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "image-feat" }
        tag_list: { in: "p-home-tab" }
      }
    ) {
      nodes {
        content
      }
    }
    imgFeatMob: allStoryblokEntry(
      filter: {
        lang: { eq: "default" }
        field_component: { eq: "image-feat" }
        tag_list: { in: "p-home-mob" }
      }
    ) {
      nodes {
        content
      }
    }
  }
`;

export default Index;
